export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Jon's Thoughts",

  author: "Jon Degn",
  description: "A place to share my thoughts and what I'm working on.",

  // The number of posts to a page on the site index.
  indexPageSize: 10
};
